import Icon from "domains/ui/components/Icon";

import { BackgroundTask } from "../interfaces/BackgroundTask";

interface BackgroundTaskIconProps {
  type: BackgroundTask["type"];
}

export default function BackgroundTaskIcon({ type }: BackgroundTaskIconProps) {
  if (type === "modelTraining" || type === "modelUpload") {
    return <Icon id="Nav/Model/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "modelDownload") {
    return <Icon id="Ui/Download" h="16px" color="textSecondary" />;
  }

  if (type === "inference") {
    return <Icon id="Nav/Image/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "assetsDownload") {
    return <Icon id="Ui/Download" h="16px" color="textSecondary" />;
  }

  if (type === "upscale") {
    return <Icon id="Nav/Upscale/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "vectorize") {
    return <Icon id="Nav/Vectorize/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "pixelate") {
    return <Icon id="Nav/Pixelate/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "bgRemove") {
    return <Icon id="Nav/BgRemove/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "restyle") {
    return <Icon id="Nav/Restyle/Outline" h="16px" color="textSecondary" />;
  }

  if (type === "skybox") {
    return <Icon id="Nav/Skybox/Solid" h="16px" color="textSecondary" />;
  }

  if (type === "texture") {
    return <Icon id="Nav/Texture/Solid" h="16px" color="textSecondary" />;
  }

  return null;
}
