import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { ButtonProps } from "domains/ui/components/Button/index";
import { useUser } from "domains/user/hooks/useUser";
import { GetJobsApiResponse, useGetJobsQuery } from "infra/api/generated/api";
import _ from "lodash";
import moment from "moment";

import { skipToken } from "@reduxjs/toolkit/query";

import { BackgroundTask } from "../interfaces/BackgroundTask";

interface BackgroundTaskContextType {
  open: () => void;
  close: () => void;
  clearTask: (id: string) => void;
  refresh: () => void;
  loadMore: () => void;
  recentTasks: BackgroundTask[];
  tasks: BackgroundTask[];
  isOpen: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  hasMore: boolean;
}

export const BackgroundTaskContext = createContext<BackgroundTaskContextType>({
  open: () => {},
  close: () => {},
  clearTask: () => {},
  refresh: () => {},
  loadMore: () => {},
  recentTasks: [],
  tasks: [],
  isOpen: false,
  isLoading: false,
  isRefreshing: false,
  hasMore: false,
});

// ------------------------------------

interface BackgroundTaskProviderProps {
  children?: React.ReactNode;
}

export function BackgroundTaskProvider({
  children,
}: BackgroundTaskProviderProps) {
  const { userId, updateUserSettings, userSettings } = useUser();
  const { selectedTeam } = useTeamContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [paginationToken, setPaginationToken] = useState<string | undefined>();

  const clearedTaskIds = useMemo(
    () => (_.get(userSettings, "cleared-tasks") ?? []) as string[],
    [userSettings]
  );

  const { data: jobsData, refetch: refresh } = useGetJobsQuery({
    teamId: selectedTeam.id,
    pageSize: "20",
  });

  useGetJobsQuery(
    paginationToken
      ? {
          teamId: selectedTeam.id,
          pageSize: "20",
          paginationToken,
        }
      : skipToken
  );

  const filteredJobs = useMemo(
    () =>
      jobsData?.jobs.filter(
        (job) => job.authorId === userId && job.status !== "canceled"
      ) ?? [],
    [jobsData, userId]
  );

  const tasks = useMemo(() => {
    return filteredJobs
      .map((job) => {
        const type =
          (job.jobType === "inference" &&
            _.get(job, "metadata.input.type", "").includes("texture") &&
            "texture") ||
          (job.jobType === "flux" && "inference") ||
          (job.jobType === "texture" && "textureMaps") ||
          (job.jobType === "flux-model-training" && "model-training") ||
          job.jobType;

        return {
          id: `job-${job.jobId}`,
          date: job.createdAt,
          type: getType(type),
          label: getLabel(type, job),
          status: getStatus(job),
          link: getLink(type, job),
          progress: job.progress ? job.progress * 100 : undefined,
        };
      })
      .filter((job) => job.type !== undefined) as BackgroundTask[];
  }, [filteredJobs]);

  const recentTasks = useMemo(
    () =>
      tasks
        .filter(
          (task) =>
            !clearedTaskIds.includes(task.id) &&
            moment.utc().diff(moment.utc(task.date), "minutes") < 4 * 60
        )
        .slice(0, 8),
    [tasks, clearedTaskIds]
  );

  const hasMore = !!jobsData?.nextPaginationToken;
  const isLoading = useMemo(
    () => !!recentTasks.find((task) => task.status === "loading"),
    [recentTasks]
  );

  // ----------------------------------

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  const clearTask = useCallback(
    async (id: string) => {
      const taskIds = tasks.map((task) => task.id);
      const newClearedTasks = [
        ...clearedTaskIds.filter((id) => taskIds.includes(id)),
        id,
      ];

      await updateUserSettings({
        "cleared-tasks": newClearedTasks,
      });
    },
    [updateUserSettings, clearedTaskIds, tasks]
  );

  const loadMore = useCallback(() => {
    if (jobsData?.nextPaginationToken) {
      setPaginationToken(jobsData.nextPaginationToken);
    }
  }, [jobsData?.nextPaginationToken]);

  // ----------------------------------

  useEffect(() => {
    const isRefreshing =
      filteredJobs.findIndex((job) =>
        ["in-progress", "warming-up", "queued"].includes(job.status)
      ) >= 0;
    setIsRefreshing(isRefreshing);
  }, [filteredJobs, setIsRefreshing]);

  // This useEffect cannot be replaced by pollingInterval due to a bug that transfer
  // the paginationToken to all same queries (see the 2 same useGetJobsQuery hooks)
  useEffect(() => {
    if (!isRefreshing) return;
    const interval = setInterval(refresh, 5_000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isRefreshing, refresh]);

  // ----------------------------------

  const backgroundTaskContextValue = useMemo(
    () => ({
      open,
      close,
      clearTask,
      refresh,
      loadMore,
      tasks,
      recentTasks,
      isOpen,
      isLoading,
      isRefreshing,
      hasMore,
    }),
    [
      open,
      close,
      clearTask,
      refresh,
      loadMore,
      tasks,
      recentTasks,
      isOpen,
      isLoading,
      isRefreshing,
      hasMore,
    ]
  );

  return (
    <BackgroundTaskContext.Provider value={backgroundTaskContextValue}>
      {children}
    </BackgroundTaskContext.Provider>
  );
}

export function useBackgroundTaskContext() {
  return useContext<BackgroundTaskContextType>(BackgroundTaskContext);
}

// ------------------------------------

function getType(type: string): BackgroundTask["type"] | undefined {
  return (
    (
      {
        inference: "inference",
        upscale: "upscale",
        pixelate: "pixelate",
        vectorize: "vectorize",
        "remove-background": "bgRemove",
        restyle: "restyle",
        "skybox-base-360": "skybox",
        "skybox-upscale-360": "skybox",
        texture: "texture",
        textureMaps: "texture",
        "upscale-texture": "texture",
        "model-training": "modelTraining",
        "model-import": "modelUpload",
        "assets-download": "assetsDownload",
        "model-download": "modelDownload",
      } as const
    )[type] ?? undefined
  );
}

function getLabel(
  type: string,
  job: GetJobsApiResponse["jobs"][number]
): BackgroundTask["label"] | undefined {
  return (
    {
      "model-training": "Model Training",
      "model-import": "Model Upload",
      "model-download": "Model Download",
      inference: _.compact([
        "Image",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      upscale: _.compact([
        "Enhance",
        _.get(job?.metadata.input, "scalingFactor")
          ? `${_.get(job?.metadata.input, "scalingFactor")}x`
          : undefined,
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      vectorize: "Vectorization",
      pixelate: "Pixelate",
      "remove-background": "Remove Background",
      restyle: _.compact([
        "Restyle",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "skybox-base-360": _.compact([
        "Skybox",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "skybox-upscale-360": _.compact([
        "Skybox Enhance",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      texture: _.compact([
        "Texture",
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      textureMaps: "Texture Maps",
      "upscale-texture": _.compact([
        "Texture Enhance",
        _.get(job?.metadata.input, "scalingFactor")
          ? `${_.get(job?.metadata.input, "scalingFactor")}x`
          : undefined,
        _.get(job?.metadata.input, "prompt"),
      ]).join(", "),
      "assets-download": "Assets Download",
    }[type] ?? undefined
  );
}

function getStatus(
  job: GetJobsApiResponse["jobs"][number]
): BackgroundTask["status"] | undefined {
  return (
    (
      {
        canceled: "failed",
        failure: "failed",
        success: "succeeded",
        "in-progress": "loading",
        "warming-up": "loading",
        queued: "loading",
      } as const
    )[job.status] ?? undefined
  );
}

function getLink(
  type: string,
  job: GetJobsApiResponse["jobs"][number]
): BackgroundTask["link"] {
  if (
    [
      "inference",
      "upscale",
      "pixelate",
      "vectorize",
      "remove-background",
      "restyle",
    ].includes(type) &&
    job.status === "success" &&
    (job.metadata.assetIds ?? []).length
  ) {
    return {
      pathname: "/images",
      query: { openAssetId: _.last(_.get(job.metadata, "assetIds")) },
    } as ButtonProps["internalLink"];
  } else if (
    ["skybox-base-360", "skybox-upscale-360"].includes(type) &&
    job.status === "success" &&
    (job.metadata.assetIds ?? []).length
  ) {
    return {
      pathname: "/skyboxes",
      query: { openAssetId: _.last(_.get(job.metadata, "assetIds")) },
    } as ButtonProps["internalLink"];
  } else if (
    ["texture", "textureMaps", "upscale-texture"].includes(type) &&
    job.status === "success" &&
    (job.metadata.assetIds ?? []).length
  ) {
    return {
      pathname: "/textures",
      query: { openAssetId: _.last(_.get(job.metadata, "assetIds")) },
    } as ButtonProps["internalLink"];
  } else if (
    type === "model-training" ||
    (type === "model-import" && _.get(job.metadata, "modelId"))
  ) {
    return {
      pathname: "/models/[id]",
      query: {
        id: _.get(job.metadata, "modelId"),
      },
    } as ButtonProps["internalLink"];
  } else {
    return undefined;
  }
}
