export class AnalyticsEvents {
  static readonly Login = {
    RenderedLoginPage: "login/renderedLoginPage",
    EnteredEmail: "login/enteredEmail",
    InvalidEmail: "login/invalidEmail",
    EnteredCode: "login/enteredCode",
    InvalidCode: "login/invalidCode",
    UserLoggedIn: "login/userLoggedIn",
  };

  static readonly CreateModel = {
    ClickedCategory: "createModel/chooseCategory",
    ClickedClass: "createModel/chooseClass",
    HelpChooseClass: "createModel/helpChooseClass",

    AddedTrainingImage: "createModel/addMoreImages",

    DeletedTrainingImage: "createModel/deleteImage",
    DeletedAllTrainingImage: "createModel/deleteAllImages",
    UpdatedTrainingImageDescription: "createModel/updateImageDescription",

    TrainingQueued: "createModel/triggerTraining",

    TrainingSampleBackgroundRemoved: "createModel/removeImageBackground",
  };

  static readonly Navigation = {
    ClickedModels: "app/clickModels",
    ClickedImages: "app/clickImages",
    ClickedCanvas: "app/clickCanvas",
    ClickedLogout: "app/clickLogOut",
    ClickedUpgrade: "app/clickUpgrade",
    ClickedSpeedUpProcessing: "app/clickSpeedUpProcessing",
    ClickedSubscriptionEndingSoon: "app/clickSubscriptionEndingSoon",

    ClickedHelpCenter: "app/clickHelpCenter",
    ClickedChat: "app/clickChat",
    ClickedFeedback: "app/clickFeedback",
    ClickedSupport: "app/clickSupport",
    ClickedTeamSettings: "app/clickTeamSettings",
    ClickedProfile: "app/clickProfile",

    ClickedPin: "app/clickPin",

    ClickedUnity: "app/clickUnity",

    ClickedPixelate: "app/clickPixelate",
    ClickedUpscale: "app/clickUpscale",
    ClickedRemoveBackground: "app/clickRemoveBackground",
    ClickedRestyle: "app/clickRestyle",
    ClickedVectorize: "app/clickVectorize",

    ClickedSkyboxes: "app/clickSkyboxes",
    ClickedUpscaleSkybox: "app/clickUpscaleSkybox",

    ClickedTextures: "app/clickTextures",
    ClickedUpscaleTexture: "app/clickUpscaleTexture",
  };

  static readonly Model = {
    Copied: "model/copied",
    Deleted: "model/deleted",
    Exported: "model/exported",
    CopiedTrained: "model/copiedTrained",
    UploadedThumbnail: "model/uploadedThumbnail",
    Uploaded: "model/uploaded",
    UsedDeprecatedGeneratorId: "model/usedDeprecatedGeneratorId",
    UsedDeprecatedGeneratorRoute: "model/usedDeprecatedGeneratorRoute",
  };

  static readonly Inference = {
    SelectedModel: "generateInference/confirmModel",
    CreatedInference: "generateInference/trigger",
    SelectedPromptModifier: "generateInference/selectedPromptModifier",
    SelectedSuggestedToken: "generateInference/selectedSuggestedToken",
    ImportedInference: "generateInference/importedInference",
    ClearPrompt: "generateInference/clearPrompt",
    EditPrompt: "generateInference/editPrompt",
    EditTag: "generateInference/editTag",
    ImportedReferenceImage: "generateInference/importedReferenceImage",
    ImportedIpAdapterImage: "generateInference/importedIpAdapterImage",
    UsedDeprecatedImportAssetId:
      "generateInference/usedDeprecatedImportAssetId",
    ImportedSecondReferenceImage:
      "generateInference/importedSecondReferenceImage",
  };

  static readonly ImageLibrary = {
    RemovedBackground: "inferences/removeBackground",
    UpscaledImage: "inferences/upscaledImage",
    DownloadedImage: "imageLibrary/downloadedImage",
    PixelatedImage: "imageLibrary/pixelatedImage",
    DeletedInference: "imageLibrary/deleteInference",
    DeletedInferenceImage: "imageLibrary/deleteInferenceImage",
    DeletedImages: "imageLibrary/deletedImages",
    UploadedImage: "imageLibrary/uploadedImage",
    VectorizedImage: "imageLibrary/vectorizedImage",
  };

  static readonly Signup = {
    ReferralCaptured: "signup/referralCaptured",
  };

  static readonly User = {
    PolicyAccepted: "user/policyAccepted",
    Onboarded: "user/onboarded",
    accountDeleted: "user/accountDeleted",
  };

  static readonly Canvas = {
    ImportedImage: "canvas/importedImage",
    InpaintingInference: "canvas/inpaintingInference",
    Img2ImgInference: "canvas/img2imgInference",
    Txt2ImgInference: "canvas/txt2imgInference",
    DrawMask: "canvas/drawMask",
    Sketch: "canvas/sketch",
    Downloaded: "canvas/downloaded",
    NewCanvasCreated: "canvas/newCanvasCreated",
    UndoTriggered: "canvas/undoTriggered",
    RedoTriggered: "canvas/redoTriggered",
    CreatedInference: "canvas/inference",
    Delete: "canvas/delete",
    DisplayLockModal: "canvas/displayLockModal",
    Download: "canvas/download",
    Draw: "canvas/draw",
    Duplicate: "canvas/duplicate",
    ForceUnlock: "canvas/forceUnlock",
    ImportedFromGallery: "canvas/importedFromGallery",
    NewProject: "canvas/newProject",
    OpenSection: "canvas/openSection",
    RemoveBackground: "canvas/removeBackground",
    Search: "canvas/search",
    Upload: "canvas/upload",
    Upscale: "canvas/upscale",

    LiveToggle: "canvas/liveToggle",
    SeedClick: "canvas/seedClick",
    OpenedSetting: "canvas/openedSetting",
    SendOutputToInput: "canvas/sendOutputToInput",
    ImageClicked: "canvas/imageClicked",
  };

  static readonly Gallery = {
    ChangeGridView: "gallery/changeGridView",
    ChangeColumnsNumber: "gallery/changeColumnsNumber",
  };

  static readonly Team = {
    CreatedTeam: "team/createdTeam",
    DeletedTeam: "team/deletedTeam",
    AddedMember: "team/addedMember",
    RemovedMember: "team/removedMember",
    CreatedApiKey: "team/createdApiKey",
    DeletedApiKey: "team/deletedApiKey",
    LeftTeam: "team/memberLeft",
    RemovedPendingMember: "team/removedPendingMember",
  };

  static readonly Subscription = {
    RedirectedToPaymentPage: "subscription/redirectedToPaymentPage",
    CancelledPlan: "subscription/cancelledPlan",
    UpgradedPlan: "subscription/upgradedPlan",
    DowngradedPlan: "subscription/downgradedPlan",
    ReactivatedPlan: "subscription/reactivatedPlan",
    AddedSeats: "subscription/addedSeats",
    RemovedSeats: "subscription/removedSeats",
  };

  static readonly Oscu = {
    RedirectedToPaymentPage: "oscu/redirectedToPaymentPage",
  };

  static readonly Search = {
    Searched: "search/searched",
    ImportedImage: "search/importedImage",
  };

  static readonly AssetZoom = {
    UsedDeprecatedOpenAssetId: "assetZoom/usedDeprecatedOpenAssetId",
    CreatePreset: "assetZoom/createPreset",
    OpenInUnity: "assetZoom/openInUnity",
    Downloaded: "assetZoom/downloaded",
  };

  static readonly Pixelate = {
    ImportedReferenceImage: "pixelate/importedReferenceImage",
  };

  static readonly Upscale = {
    ImportedReferenceImage: "upscale/importedReferenceImage",
  };

  static readonly RemoveBackground = {
    ImportedReferenceImage: "removeBackground/importedReferenceImage",
  };

  static readonly Vectorize = {
    ImportedReferenceImage: "vectorize/importedReferenceImage",
  };

  static readonly Skybox = {
    ImportedStyleImage: "skybox/importedStyleImage",
    ImportedStructureImage: "skybox/importedStructureImage",
    ImportedDepthImage: "skybox/importedDepthImage",
    GeneratedSkyboxes: "skybox/generatedSkyboxes",
    UpscaledSkybox: "skybox/upscaledSkybox",
  };

  static readonly SkyboxUpscale = {
    ImportedStyleImage: "skyboxUpscale/importedStyleImage",
  };

  static readonly Restyle = {
    CreatedInference: "restyle/trigger",
    ImportedReferenceImage: "restyle/importedReferenceImage",
    ImportedStyleImage: "restyle/importedStyleImage",
  };

  static readonly Home = {
    ClickedTool: "home/clickedTool",
    ClickedViewAllTools: "home/clickedViewAllTools",

    ClickedUseCase: "home/clickedUseCase",

    ClickedTutorial: "home/clickedTutorial",
  };

  static readonly Tools = {
    ClickedTool: "tools/clickedTool",
  };

  static readonly Blend = {
    Tested: "blend/tested",
    Created: "blend/created",
  };

  static readonly TourGuide = {
    openedTutorial: "tourGuide/openedTutorial",
    closedTutorial: "tourGuide/closedTutorial",
    openedPage: "tourGuide/openedPage",
    closedPage: "tourGuide/closedPage",
    clickedLearnMore: "tourGuide/clickedLearnMore",
  };
}
