import { useMemo, useState } from "react";
import Link, { LinkProps } from "next/link";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { extraTheme } from "domains/theme";
import Avatar from "domains/ui/components/Avatar";
import Button from "domains/ui/components/Button";
import DecisionModal from "domains/ui/components/DecisionModal";
import Icon, { IconId } from "domains/ui/components/Icon";
import MenuItem from "domains/ui/components/Menu/MenuItem";
import { useUser } from "domains/user/hooks/useUser";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";

type ActionListItem =
  | {
      id: string;
      iconId: IconId;
      label: string;
      link?: LinkProps["href"];
      onClick?: "signOut" | "newWorkspace" | (() => void);
      dataTestId?: string;
    }
  | "divider";

const ACTION_LIST: ActionListItem[] = [
  {
    id: "workspaceSettings",
    iconId: "Layout/Settings",
    label: "Workspace Settings",
    link: "/team",
    dataTestId: "user-menu-workspace-settings-button",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedTeamSettings);
    },
  },
  {
    id: "newWorkspace",
    iconId: "Ui/Plus",
    label: "New Workspace",
    onClick: "newWorkspace",
    dataTestId: "user-menu-new-workspace-button",
  },
  "divider",
  {
    id: "personalProfile",
    iconId: "Layout/Profile",
    label: "Profile",
    link: "/profile",
    dataTestId: "user-menu-profile-button",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedProfile);
    },
  },
  "divider",
  {
    id: "signOut",
    iconId: "Layout/SignOut",
    label: "Logout",
    onClick: "signOut",
    dataTestId: "user-menu-logout-button",
  },
];

export default function WorkspaceButtonDropdown() {
  const { logout } = useUser();
  const { teams, selectedTeam, setSelectedTeam, showCreateModal } =
    useTeamContext();

  const [isSignOutConfirmDialogOpen, setIsSignOutConfirmDialogOpen] =
    useState<boolean>(false);

  const isTeamLoading = teams.length === 0;
  const canUpgrade = selectedTeam.plan !== "enterprise";

  const actionList = useMemo(
    () =>
      ACTION_LIST.filter((item) => {
        if (item === "divider") return true;
        else if (item.id === "upgrade" && !canUpgrade) return false;
        return true;
      }),
    [canUpgrade]
  );

  const handleSignOut = () => {
    Track(AnalyticsEvents.Navigation.ClickedLogout);
    logout();
    setIsSignOutConfirmDialogOpen(false);
  };

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          w="auto"
          h="auto"
          p={0}
          _hover={{
            bgColor: "none",
            opacity: 0.7,
          }}
          _active={{
            bgColor: "none",
            opacity: 1,
          }}
          colorScheme="white"
          data-testid="topbar-user-setting-dropdown-button"
          rightIcon={<Icon id="Ui/ChevronDownSm" />}
          variant="ghost"
        >
          <Avatar
            w="36px"
            h="36px"
            label={selectedTeam.name}
            borderRadius="full"
            size="sm"
          />
        </MenuButton>

        <MenuList
          overflowY="auto"
          w="220px"
          maxH="80vh"
          px={2}
          data-testid="topbar-user-setting-dropdown-content"
        >
          <MenuGroup title="Workspaces">
            {isTeamLoading && <Spinner ml={2} size="xs" />}

            {teams.map((team) => {
              const isSelected = selectedTeam.id === team.id;
              return (
                <MenuItem
                  key={team.id}
                  check="icon"
                  text={team.name}
                  isSelected={isSelected}
                  onClick={() => setSelectedTeam(team)}
                />
              );
            })}
          </MenuGroup>

          <MenuDivider />

          {actionList.map((item, idx) => {
            if (item === "divider") {
              return <MenuDivider key={`divider-${idx}`} />;
            }

            const onActionClick = (() => {
              if (item.onClick === "newWorkspace") {
                return showCreateModal;
              } else if (item.onClick === "signOut") {
                return () => {
                  setIsSignOutConfirmDialogOpen(true);
                };
              } else if (typeof item.onClick === "function") {
                return item.onClick;
              }
            })();

            return (
              <ActionMenuItem
                key={item.id}
                text={item.label}
                icon={item.iconId}
                link={item.link}
                onClick={onActionClick}
                dataTestId={item.dataTestId}
              />
            );
          })}
        </MenuList>
      </Menu>

      <DecisionModal
        isOpen={isSignOutConfirmDialogOpen}
        headerMessage="Confirm Logout"
        body="Are you sure you want to log out?"
        onConfirm={handleSignOut}
        onClose={() => setIsSignOutConfirmDialogOpen(false)}
        colorScheme="danger"
        confirmMessage="Logout"
      />
    </>
  );
}

// ------------------------------------

interface ActionMenuItemProps {
  text: string;
  icon?: IconId;
  link?: LinkProps["href"];
  onClick?: () => void;
  dataTestId?: string;
}

function ActionMenuItem({
  text,
  icon,
  link,
  onClick,
  dataTestId,
}: ActionMenuItemProps) {
  return (
    <Button
      data-group
      {...(link
        ? {
            as: Link,
            href: link,
          }
        : {})}
      colorScheme="white"
      variant="ghost"
      _hover={{
        bg: "whiteAlpha.200",
      }}
      aria-label={text}
      data-testid={dataTestId}
      textTransform="none"
      size="md"
      justifyContent="flex-start"
      transition={extraTheme.transitions.fast}
      fontWeight="normal"
      borderRadius="lg"
      px={2}
      gap={3}
      w="full"
      onClick={onClick}
      leftIcon={
        icon ? (
          <Flex justify="center">
            <Icon color="textPrimary" id={icon} w="20px" />
          </Flex>
        ) : undefined
      }
    >
      <Text
        align="left"
        color="textSecondary"
        fontSize="sm"
        _groupHover={{ color: "textPrimary" }}
        transition={extraTheme.transitions.fast}
        isTruncated
      >
        {text}
      </Text>
    </Button>
  );
}
